
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































.video-popup,
[class*='video-popup--'] {
  position: relative;
  width: 90vw;
  max-width: 100rem;
}

.video-popup__video {
  ::v-deep .video__inner {
    width: 100%;
    max-width: none;
    padding-right: 0;
    padding-left: 0;
  }
}

.video-popup__close {
  position: absolute;
  z-index: 10;
  top: -5rem;
  right: -1rem;
  padding: 1rem;
  background: transparent;
  border: 0;

  ::v-deep svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: $white;
  }

  @include mq(m) {
    top: -5rem;
    right: -5rem;
  }
}
